'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import { Environment, type FoundationError, type PlayerInterface } from '@foundation-player/loader';
import { createPlayerUI } from '@foundation-player/ui';
import { clsx } from 'clsx';
import { AppContext } from 'context/AppContext';
import { useConsent } from 'customHooks/useConsent';
import { useQueryExist } from 'customHooks/useQueryExist';
import { useQueryParam } from 'customHooks/useQueryParam';
import type { VideoProps } from 'types/videoTypes';
import { fetchWithTimeout } from 'utils/fetch';
import { getImageLoader } from 'utils/imageLoader';
import { geoAllowed } from 'utils/videoPlayer';
import {
  createMediaConfig,
  createPlayerLoaderConfig,
  createUiConfig,
  dateErrorMessage,
  defaultErrorMessage,
  geoBlockingErrorMessage,
} from './config';
import styles from './FoundationPlayer.module.scss';

export type FoundationPlayerProps = {
  video: VideoProps;
  postRollDisabled?: boolean;
  autoplay?: boolean;
  currentVideoIndex?: number;
  isAdPlayingListener?: (value: boolean) => void;
  totalClipsListener?: (value: number) => void;
  totalDurationListener?: (value: number) => void;
  currentAdTimeListener?: (value: number) => void;
  contentFinishedListener?: (value: boolean) => void;
  videoPlayingListener?: (value: boolean) => void;
  showRelatedVideosListener?: (value: boolean) => void;
};

const FoundationPlayer = ({
  video,
  postRollDisabled,
  autoplay = false,
  currentVideoIndex = 0,
  isAdPlayingListener,
  totalClipsListener,
  totalDurationListener,
  currentAdTimeListener,
  contentFinishedListener,
  videoPlayingListener,
  showRelatedVideosListener,
}: FoundationPlayerProps) => {
  const {
    analyticsData: { ivwCode },
    pageMetaData: { noVideoAds },
    env,
    version,
  } = useContext(AppContext);
  const {
    consent: { tcString },
    isConsentReady,
  } = useConsent();
  const device = useQueryParam('device');
  const adsDisabled = useQueryExist('video.adsOff') || noVideoAds || video.fields['ads-free'] === 'true';
  const playerRef = useRef<PlayerInterface>(null);
  const playerElementRef = useRef<HTMLDivElement>(null);

  const posterUrl = getImageLoader(video.image.baseUrl, video.image.filename, 'c16_9').loader({
    src: video.image.baseUrl,
    width: 1024,
  });

  const [playerCreated, setPlayerCreated] = useState<boolean>(false);
  const [sxpKeys, setSxpKeys] = useState<string | undefined>();
  const [isVideoAllowed, setIsVideoAllowed] = useState<boolean>(true);
  const [videoErrorMessage, setVideoErrorMessage] = useState<string>(defaultErrorMessage);
  const [isGeoAllowed, setIsGeoAllowed] = useState<boolean>(true);
  const [isDateAllowed, setIsDateAllowed] = useState<boolean>(true);

  let heartbeatOffer = 'rtl';
  switch (device?.toLowerCase()) {
    case 'ios':
      heartbeatOffer = 'apprtlin';
      break;
    case 'android':
      heartbeatOffer = 'aadrtlin';
      break;
    default:
      heartbeatOffer = 'rtl';
  }

  async function createPlayerUIAndLoadMedia() {
    try {
      if (playerElementRef.current && sxpKeys) {
        const uiConfig = createUiConfig(posterUrl);
        const playerLoaderConfig = createPlayerLoaderConfig(
          tcString,
          heartbeatOffer,
          !adsDisabled,
          env as Environment,
          version
        );
        const mediaConfig = createMediaConfig({
          ads: !adsDisabled,
          ivwCode,
          postRoll: !postRollDisabled,
          poster: posterUrl,
          tcString,
          video,
          autoplay,
          moltenVideoTracking: sxpKeys,
          currentVideoIndex,
        });
        setPlayerCreated(true);
        playerRef.current = await createPlayerUI(playerElementRef.current, playerLoaderConfig, uiConfig);
        playerRef.current.loadMedia(mediaConfig).catch((error: FoundationError) => {
          playerRef.current?.destroy(error);
        });

        playerRef.current?.addEventListener('advertising:AdSlotStarted', (e) => {
          isAdPlayingListener && isAdPlayingListener(true);
          totalClipsListener && totalClipsListener(e.adSlot.getClips());
          totalDurationListener && totalDurationListener(e.adSlot.getDuration());
        });
        playerRef.current?.addEventListener(
          'advertising:AdSlotStopped',
          () => isAdPlayingListener && isAdPlayingListener(false)
        );
        playerRef.current?.addEventListener(
          'player:TimeChanged',
          (e) => currentAdTimeListener && currentAdTimeListener(e.time)
        );
        playerRef.current?.addEventListener('player:ContentStart', () => {
          videoPlayingListener && videoPlayingListener(true);
        });
        playerRef.current?.addEventListener('player:ContentEnd', () => {
          contentFinishedListener && contentFinishedListener(true);
          videoPlayingListener && videoPlayingListener(false);
          showRelatedVideosListener && showRelatedVideosListener(true);
        });
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  useEffect(() => {
    fetchWithTimeout('/proxy/geoip/', { next: { revalidate: 1 } })
      .then((data) => {
        setIsGeoAllowed(geoAllowed(video.fields['regions-allowed'], data.country));
      })
      .catch((error) => {
        console.error('GeoIP Api fetch error:', error);
      });

    setIsDateAllowed(!!video.fields.encoding);
  }, [video.fields]);

  useEffect(() => {
    if (!isGeoAllowed) {
      setIsVideoAllowed(isGeoAllowed);
      setVideoErrorMessage(geoBlockingErrorMessage);
    }
    if (!isDateAllowed) {
      setIsVideoAllowed(isDateAllowed);
      setVideoErrorMessage(dateErrorMessage);
    }
  }, [isGeoAllowed, isDateAllowed]);

  useEffect(() => {
    if (isConsentReady && sxpKeys && isVideoAllowed) {
      createPlayerUIAndLoadMedia();
    }

    return () => {
      playerRef.current?.removeEventListener('advertising:AdSlotStarted', () => null);
      playerRef.current?.removeEventListener('advertising:AdStopped', () => null);
      playerRef.current?.removeEventListener('player:TimeChanged', () => null);
      playerRef.current?.removeEventListener('player:ContentEnd', () => null);
      playerRef.current?.removeEventListener('player:ContentStart', () => null);
      playerRef.current?.destroy('unmount');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsentReady, sxpKeys, isVideoAllowed]);

  useEffect(() => {
    if (window.MoltenBundle?.Video?.getSXPKeysAsync) {
      window.MoltenBundle?.Video?.getSXPKeysAsync().then((result: string) => setSxpKeys(result));
    } else {
      window.setTimeout(function () {
        window.MoltenBundle?.Video?.getSXPKeysAsync().then((result: string) => setSxpKeys(result));
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (playerCreated && sxpKeys) {
      const updatedMediaConfig = createMediaConfig({
        ads: !adsDisabled,
        ivwCode,
        postRoll: !postRollDisabled,
        poster: posterUrl,
        tcString,
        video,
        autoplay,
        moltenVideoTracking: sxpKeys,
        currentVideoIndex,
      });
      playerRef.current?.loadMedia(updatedMediaConfig).catch((error: FoundationError) => {
        playerRef.current?.destroy(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video.id]);

  return (
    <div ref={playerElementRef} className={styles.root}>
      <video poster={posterUrl} data-testid={video.id} className={clsx(styles.video, playerCreated && styles.hide)}>
        <track kind="captions" />
      </video>
      {!isVideoAllowed && <div className={styles.errorMessage}>{videoErrorMessage}</div>}
    </div>
  );
};

export default FoundationPlayer;
